.title {
    color: #00c3ff;
    background-color: white;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 14px;
}

.navbar {
    background-color: rgb(32, 32, 32);
    overflow: hidden;
    box-shadow: 0 3px 10px -5px #333;
    display: flex;
    justify-content: center;
    margin: 2rem;
    margin-bottom: 0;
    border-radius: 1rem;
}

.nav-links-container {
    width: calc(100% - 2rem);
    /* max-width: 980px; */
    display: flex;
    justify-content: space-between;
}

#end-links-container {
    display: flex;
    justify-content: right;
}

.navbar-link {
    float: left;
    color: #f2f2f2;
    text-align: center;
    text-decoration: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    margin-left: 1rem;
    margin-right: 1rem;
    white-space: nowrap;
}

.navbar-link:hover {
    color: rgb(70, 134, 218);
    transition: all 200ms ease-in-out;
}

.navbar-link.active {
    color: rgb(104, 90, 196);
    cursor: default;
}

.navbar-link.semi-active {
    color: rgb(84, 206, 173);
    cursor: pointer;
}

.navbar-link.semi-active:hover {
    color: rgb(104, 90, 196);
}

#nav-logo {
    justify-self: left;
    font-size: 1.5rem;
    padding: 0;
    font-weight: 700;
    background: var(--gradient-bg-purple-blue);
    background-clip: text;
    color: transparent;
}

.navbar-icon {
    width: 40%;
}

.content-section {
    border-style: solid;
    border-width: 2px;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 1em;
}

/* About page */

#about-header {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(100vh - 3.375rem);
    flex-direction: column;
    position: relative;
}

#about-header-subtitle {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 3rem;
    justify-content: center;
    align-items: flex-end;
}

#about-header-title {
    position: relative;
    font-size: 8rem;
    font-weight: 700;
    line-height: 1;
    padding: 1rem 2rem;
    font-family: Satori, sans-serif;
    display: block;
    text-align: center;
}

.about-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    width: calc(100vw - 4rem);
    font-size: 1.5rem;
    margin: 0 2rem;
}

.about-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #1e1e1e;
    width: 100%;
    border-radius: 1rem;
    height: 40rem;
    position: relative;
}

.about-box-title {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 1rem 0 0;
}

.about-box-icon {
    width: calc(100% - 4rem);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.about-box-text {
    margin: 0 1rem 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
}

.about-box-icon-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    width: auto;
}

.about-box-icon-grid img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10%;
    transition: filter 200ms ease-in-out;
    position: relative;
    display: inline-block;
}

.about-box-icon-grid div:hover img {
    filter: brightness(125%);
}

.about-box-icon-grid div {
    width: 5rem;
    height: 5rem;
    position: relative;
}

.about-box-icon-grid div::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(9, 9, 9);
    content: attr(data-name);
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    opacity: 0;
    z-index: 2;
    font-size: 1rem;
    transition: opacity 200ms ease-in-out;
}

.about-box-icon-grid div:hover::after {
    opacity: 90%;
}

#about-icon-container {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon-grid-subtitle {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    display: inline-block;
}

#about-card-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#about-card {
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    background-color: #1e1e1e;
    border-radius: 1rem;
    margin: 2rem 2rem;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 8rem;
    grid-template-rows: 1fr;
}

#landing-screen-container {
    width: 100vw;
    height: 100vh;
}
#screen-down-button-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#screen-down-button {
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    background-color: transparent;
    transition: border-color 200ms ease-in-out;
    border: 0.2rem solid white;
    cursor: pointer;
    background-image: url("../images/down-arrow.svg");
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: center;
}

#screen-down-button:hover {
    border-color: var(--accent-color-blue);
}

.about-card-page {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-column: 1/2;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    opacity: 0;
    transition: opacity 200ms;
}

.active-card {
    opacity: 1;
}

.about-card-title {
    align-self: flex-end;
    font-size: 4rem;
    font-weight: 600;
    margin: 2rem 0 0.5rem 2rem;
    grid-column: 1/2;
    grid-row: 1/2;
}

.about-card-icon {
    margin-top: 2rem;
    width: calc(100% - 8rem);
    align-self: center;
    justify-self: center;
    background-color: #2d2d2d;
    padding: 2rem;
    border-radius: 1rem;
}

.about-card-text {
    font-size: 2rem;
    font-weight: lighter;
    padding: 0.5rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    grid-column: 1/2;
    grid-row: 2/3;
}

#card-scroller {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    grid-column: 2/3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card-indicator {
    margin: 1rem;
    width: 1rem;
    height: 8rem;
    background-color: #3d3d3d;
    border-radius: 0.5rem;
    transition: background-color 200ms;
}

.active-card-indicator {
    background-color: var(--accent-color-blue);
}

.card-control-button {
    margin: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    background-color: transparent;
    border: 0.2rem solid white;
    transition: border-color 200ms;
    background-image: url("../images/down-arrow.svg");
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: center;
}

.card-control-button:hover {
    border-color: var(--accent-color-blue);
    cursor: pointer;
}

.about-card-right {
    width: 100%;
    height: 100%;
    grid-column: 2/3;
    grid-row: 1/3;
    display: flex;
    justify-content: center;
}

.about-icon-group {
    margin: 1rem 2rem;
    background-color: #2d2d2d;
    border-radius: 1rem;
    padding: 1rem;
}

.link {
    text-decoration: none;
    width: fit-content;
    background: var(--gradient-bg-purple-blue);
    color: inherit;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin-top: 1.5rem;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    font-size: 2rem;
}

.link img {
    width: 2rem;
    height: 2rem;
    margin-right: 0.75rem;
}

#card-previous {
    transform: rotate(180deg);
}

#about-interest-list {
    display: flex;
    align-self: center;
    justify-self: center;
    height: 100%;
    list-style: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

#about-interest-list li {
    font-size: 2rem;
    padding: 1rem;
    margin: 1rem 0;
    background-color: #2d2d2d;
    border-radius: 0.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 3rem auto;
    align-items: center;
    text-align: center;
}

#about-interest-list li img {
    height: 3rem;
    aspect-ratio: 1;
    margin-right: 1rem;
    justify-self: start;
}

#project-list-container {
    align-self: center;
    margin: auto;

}

#project-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.project-card {
    margin: 2rem;
    background-color: #3f3f3f;
    border-radius: 0.5rem;
    transition: transform 400ms ease-in-out;
    aspect-ratio: 1;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.brighten-on-hover {
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 200ms;
    position: absolute;
    top: 0;
    left: 0;
}

.brighten-on-hover:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.brighten-on-hover:hover .project-button {
    opacity: 1;
    transform: scale(1);
}

.project-button:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.project-title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    text-shadow: white 0 0 0.5rem;
}

.project-subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    position: absolute;
    top: 4.5rem;
    left: 1.5rem;
}

.project-icon-container {
    display: flex;
    height: 2rem;
    position: absolute;
    top: 7.5rem;
    left: 1.5rem;
    gap: 1rem;
}

.project-icon-container img {
    height: 2rem;
    width: 2rem;
    border-radius: .25rem;
}

.project-card2 {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.project-button {
    width: 25%;
    aspect-ratio: 1;
    background-color: rgba(42, 42, 42, 0.8);
    backdrop-filter: blur(0.5rem);
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition-property: transform, opacity;
    transition: 400ms;
    opacity: 0.5;
    transform: scale(0.6);
    transform-origin: bottom;

}

.project-button-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    /*text-shadow: white 0 0 0.5rem;*/
    text-align: center;
    transition: color 200ms ease-in-out;
}

.project-button-icon {
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
}

@media only screen
and (min-width: 350px)
and (max-width: 600px)
and (orientation: portrait) {
    #nav-logo {
        font-weight: 500;
        font-size: 1.5rem;
    }

    #about-header-title {
        display: inline-block;
        width: min-content;
        font-weight: 550;
        font-size: 7rem;
    }

    #about-header-subtitle {
        font-size: 2rem;
        font-weight: normal;
    }

    #about-header {
        grid-template-rows: 1fr auto 2fr;
        height: calc(var(--vh, 1vh) * 100 - 4rem);
    }

    .about-card-page {
        display: grid;
        grid-template-rows: repeat(3, 1fr) ;
        grid-template-columns: 1fr;
        text-align: center;
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .about-card-title {
        margin: 0;
        text-align: center;
        align-self: inherit;
        grid-column: 1/2;
        grid-row: 1/2;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .about-card-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-card-right {
        grid-column: 1/2;
        grid-row: 2/3;
    }

    .about-card-text {
        grid-column: 1/2;
        grid-row: 3/4;
    }

    #about-card {
        grid-template-rows: 1fr auto;
        grid-template-columns: 1fr;
    }

    #card-scroller {
        grid-column: 1/2;
        grid-row: 2/3;
        flex-direction: row;
        margin-bottom: 1rem;
        height: fit-content;
    }

    .card-indicator {
        margin: 0.5rem;
        width: 8rem;
        height: 1rem;
    }

    #card-previous {
        transform: rotate(90deg);
    }

    #card-next {
        transform: rotate(270deg);
    }

    #about-card-container {
        height: calc(var(--vh, 1vh) * 100);
    }

    #project-list {
        grid-template-columns: 1fr;
    }

    .project-title {
        text-shadow: none;
        font-weight: 550;
    }

    .project-card:hover {
        transform: scale(1);
    }

    .project-button {
        opacity: 1;
    }
}