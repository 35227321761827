.project-header {
    position: relative;
    width: 100vw;
    height: 500px;
    max-height: 67vw;
    overflow: hidden;
}

.project-header-background {
    position: absolute;
    min-width: 100%;
    max-width: 125%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .4;
}

.project-header-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    filter: drop-shadow(0 0 6px white);
    white-space: nowrap;
}

.project-header-text h1 {
    font-size: 3rem;
    margin: 0;
    padding: 0;
}

.project-header-text h3 {
    font-size: 2rem;
    margin: 0;
    padding: 0;
    color: rgb(238, 238, 238);
}

.project-header-text h5 {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    color: rgb(238, 238, 238);
}

.tool-container {
    display: flex;
    margin: 10px;
    background-color: #333;
    flex-direction: column;
    align-items: center;
}

.setting-title {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

div.setting-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

#filter-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.filter-title {
    display: inline-block;
    flex-grow: 1;
    text-align: center;
    position: relative;
    background-color: rgb(46, 46, 46);
    height: 75px;
    transition: background-color 200ms;
}

.filter-title:hover {
    background-color: rgb(71, 71, 71);
    transition: background-color 200ms;
}


.filter-title span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: background-color 200ms;
}

.filter-option-container {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.filter-option {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.filter-option:hover {
    cursor: pointer;
}

.filter-option:checked:hover {
    cursor: default;
}

.filter-option:checked ~ span {
    background-color: rgb(85, 85, 85);
    transition: background-color 200ms;
}

#card-filter-container {
    position: relative;
    width: 100%;
    flex-direction: column;
    align-items: center;
    transition: all 200ms;
}

.filter-hide-container {
    display: none;
    position: relative;
    width: 100%;
    height: 75px;
    align-items: center;
    justify-content: center;
}


.filter-hide-container span {
    width: 0%;
    height: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.filtered-cards-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.filtered-cards-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.minimized-filter-container div #exclude-filter-ul li,
.minimized-filter-container div #include-filter-ul li {
    width: 75px;
    height: 112.5px;
}

#include-filter-ul, 
#exclude-filter-ul {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
    width: 100%;
    height: fit-content;
    margin: 0;
    padding: 0;
    list-style: none;
}

.selected-filter {
    background-color:#3d3d3d;
}

#none-filter-ul {
    display: none;
    grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

#none-filter-ul li, 
#include-filter-ul li, 
#exclude-filter-ul li {
    list-style: none;
    width: 100px;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

#none-filter-ul li img, 
#include-filter-ul li img, 
#exclude-filter-ul li img {
    width: 100%;
}

#none-filter-ul li img:hover, 
#include-filter-ul li img:hover, 
#exclude-filter-ul li img:hover {
    cursor: pointer;
}

#none-filter-ul.grayed-out-champion-container li img.champion:hover,
#none-filter-ul.grayed-out-container li img:hover {
    cursor: default;
}

.grayed-out-container img {
    filter: grayscale(50%) brightness(50%);
}

.grayed-out-champion-container img.champion {
    filter: grayscale(50%) brightness(50%);
}

#filter-hide-button {
    width: 100%;
    height: 100%;
    background-color: rgb(46, 46, 46);
    border: none;
    color: white;
    font-size: 1em;
    transition: background-color 200ms;

}

#filter-hide-button:hover {
    background-color: rgb(71, 71, 71);
    cursor: pointer;
    transition: background-color 200ms;
}

.generate-button {
    display: inline-block;
    padding: 12px;
    font-size: 1.5rem;
    background-color: #00c3ff;
    border: transparent 0px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: 100px;
    margin-top: 10px;
}

.generate-button:hover {
    background-color: #00a5d7;
    cursor: pointer;
}


.champion-setting2 {
    position: relative;
    display: flex;
    width: 100%;
    height: 75px;
    align-items: center;
    justify-content: center;
}

.champion-setting2 div {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    height: 100%;
}

.champion-setting2 div span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(46, 46, 46);
    transition: background-color 200ms;
}

.champion-option {
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
}

.champion-option:hover {
    cursor: pointer;
}

.champion-option:hover  ~ span {
    background-color: rgb(71, 71, 71);
    transition: background-color 200ms;
}

.champion-option:checked ~ span {
    background-color: rgb(85, 85, 85);
    transition: background-color 200ms;
    
}

.champion-option:checked:hover {
    cursor: default;
}

.card-container {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;
    width: 800px;
    height: 600px;
    max-width: 100%;
    max-height: 75%;
    align-items: stretch;
    margin-bottom: 2em;;
}

.card-name {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
    white-space: nowrap;
}

.card-container li {
    position: relative;
    list-style: none;
    width: 100%;
    height: 100%;
    animation: fade-slide-in 1s;
    animation-fill-mode: forwards;
    --animstep: 50ms;
}

.card-container li:nth-child(2) { animation-delay: calc(var(--animstep) * 1); }
.card-container li:nth-child(3) { animation-delay: calc(var(--animstep) * 2); }
.card-container li:nth-child(4) { animation-delay: calc(var(--animstep) * 3); }
.card-container li:nth-child(5) { animation-delay: calc(var(--animstep) * 4); }
.card-container li:nth-child(6) { animation-delay: calc(var(--animstep) * 5); }
.card-container li:nth-child(7) { animation-delay: calc(var(--animstep) * 6); }
.card-container li:nth-child(8) { animation-delay: calc(var(--animstep) * 7); }

@keyframes fade-slide-in {
    0% {
        opacity: 0;
        transform: translate(0, -10%);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}


.card-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.champion {
    top: 47.3544974%;
}

#link {
    display: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
    background-color: #00c3ff;
    width: 100%;
    padding: calc(40px - 1.5rem) 0;
    text-align: center;
}
