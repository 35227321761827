#bingo-container {
    display: flex;
    width: calc(100% - 4rem);
    background-color: #3b3b3b;
    border-radius: 1rem;
    margin: 2rem;
    flex-direction: column;
}

#bingo-term-setting-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#bingo-settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

#add-term-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#term-title {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    width: 100%;
    text-align: center;

}

#bingo-term-input {
    width: 61.8%;
    height: 3rem;
    font-size: 2rem;
    border-radius: .75rem;
}

.bingo-text-box {
    text-align: center;
    background-color: #2b2b2b;
    border: 2px solid #4e4e4e;
    color: white;
    font-weight: lighter;
}

#bingo-term-add {
    width: 38.2%;
    height: 3rem;
    font-size: 1.5rem;
    text-transform: capitalize;
}

#bingo-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    list-style: none;
    margin: 1rem;
    padding: 0;
    font-size: 2rem;
    width: calc(100% - 2rem);
    aspect-ratio: 1;
    box-sizing: border-box;
    border: 2px solid #808080;
}

.bingo-box {
    min-width: 8rem;
    min-height: 8rem;
    border: 3px solid #808080;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: border, box-shadow;
    transition-duration: 200ms;
    text-align: center;
    overflow-wrap: anywhere;
}



#full-term-list {
    list-style: none;
    font-size: 2rem;
    margin: 0;
    padding: 0;
    width: 100%;
}

#full-term-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: .5rem;
    padding: .5rem 1rem;
    transition: background-color 200ms;
}

#full-term-list li.term-list-item:hover {
    background-color: #424242;
}

li.bingo-box-hover {
    border: 2px solid var(--accent-color-blue);
    box-shadow: inset 0 0 1rem var(--accent-color-blue);
}

.free-space-set {
    border: 2px solid var(--accent-color-purple);
    box-shadow: inset 0 0 1rem var(--accent-color-purple);
}

#full-term-list li:nth-child(odd) {
    background-color: #3f3f3f;
}

.remove-term-button, .free-space-button {
    height: 2rem;
    aspect-ratio: 1;
    border: none;
    border-radius: .5rem;
    background-color: #4b4b4b;
    transition-property: background-color;
    transition-duration: 200ms;
    color: white;
    padding: 0;
}

.remove-term-button:hover, .free-space-button:hover {
    cursor: pointer;
    background-color: #676767;
}

.remove-term-button:hover + .term-text {
    color: #854141
}

.term-text {
    width: 100%;
    text-align: center;
    transition-property: color;
    transition: 200ms;
}

.free-space-active {
    background-color: #523d77;
}

.free-space-active:hover {
    background-color: #6e4f9a;
}

#bingo-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

#bingo-create-button {
    width: 61.8%;
    height: 3rem;
    text-transform: capitalize;
}

.bingo-button {
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #2b2b2b;
    border: none;
    border-radius: .75rem;
    color: white;
    margin: 1rem;
    transition: background-color 200ms;
}

.bingo-button:hover {
    cursor: pointer;
    background-color: #343434;
}

#bingo-export-settings {
    font-size: 1.5rem;
}

#number-of-pages-input {
    font-size: 1.5rem;
    height: 2rem;
    width: 4rem;
    margin-left: 1rem;
    border-radius: .75rem;
}

#number-of-pages-input::-webkit-outer-spin-button,
#number-of-pages-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#bingo-title-input {
    font-size: 3rem;
    height: 4rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
}

#bingo-card-title {
    font-size: 3rem;
    padding: 0;
    text-align: center;
    width: 100%;
    display: block;
    font-weight: 500;
    margin: 1rem 0 0;
}

#bingo-card-container {
    background-color: #424242;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


@media only screen
and (min-width: 350px)
and (max-width: 600px)
and (orientation: portrait) {
    #bingo-term-setting-group {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
    }

    #bingo-card-container {
        width: 100%;

    }

    .bingo-box {
        min-width: 4rem;
        min-height: 4rem;
        width: auto;
        height: auto;
        aspect-ratio: 1;
    }

    #bingo-grid {
        width: 100%;
    }
}