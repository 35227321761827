@font-face {
    font-family: Satori;
    src: url(../fonts/Satoshi/Satoshi-Variable.ttf);
}

@font-face {
    font-family: Inter;
    src: url(../fonts/Inter/Inter-VariableFont_slnt,wght.ttf);
}

* {
    font-family: Satori, sans-serif;
}

:root {
    --gradient-bg-purple-blue: linear-gradient(90deg,
    rgb(20, 100, 204),
    rgb(81, 67, 175),
    rgb(20, 100, 204),
    rgb(81, 67, 175)) 0 0 / 800% 100%;

    --gradient-bg-blue-green: linear-gradient(35deg,
    rgb(36, 81, 164),
    #374b9a,
    rgb(36, 81, 164),
    #374b9a) 0 0 / 800% 100%;

    --accent-color-blue: rgb(20, 100, 204);
    --accent-color-light-blue: rgb(56, 141, 194);
    --accent-color-purple: rgb(81, 67, 175);
}

@media screen and (max-width: 1720px), screen and (max-height: 860px) {
    :root {
        font-size: 15px;
    }
}

@media screen and (max-width: 1540px), screen and (max-height: calc(860px - 90px * 1)) {
    :root {
        font-size: 14px;
    }
}

@media screen and (max-width: 1360px), screen and (max-height: calc(860px - 90px * 2)) {
    :root {
        font-size: 13px;
    }
}

@media screen and (max-width: 1180px), screen and (max-height: calc(860px - 90px * 3)) {
    :root {
        font-size: 12px;
    }
}

@media screen and (max-width: 1000px), screen and (max-height: calc(860px - 90px * 4)) {
    :root {
        font-size: 11px;
    }
}

@media screen and (max-width: 820px), screen and (max-height: calc(860px - 90px * 5)) {
    :root {
        font-size: 10px;
    }
}

@media screen and (max-width: 640px), screen and (max-height: calc(860px - 90px * 6)) {
    :root {
        font-size: 9px;
    }
}


.display-none {
    display: none;
}

html, body {
    padding: 0;
    margin: 0;
    background-color: rgb(43, 43, 43);
    color: white;
}

html::-webkit-scrollbar {
    display: none;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

.gradient-text {
    color: transparent;
    background: var(--gradient-bg-purple-blue);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-animation: text-gradient-anim 14s ease infinite;
    animation: text-gradient-anim 14s ease infinite;
}

.gradient-text-on-hover:hover {
    color: transparent;
    background: var(--gradient-bg-purple-blue);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-animation: text-gradient-anim 14s ease infinite;
    animation: text-gradient-anim 14s ease infinite;
}

.gradient-border {
    --borderWidth: 12px;
    border-radius: var(--borderWidth);
    position: relative;
    height: fit-content;
}

.gradient-border:before {
    content: '';
    position: absolute;
    background: rgb(43, 43, 43);
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: var(--borderWidth)
}

.gradient-border > * {
    position: relative;
    z-index: 2;
}

.gradient-border:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: var(--gradient-bg-purple-blue);
    border-radius: calc(2 * var(--borderWidth));
    z-index: 0;
    animation: text-gradient-anim 14s ease infinite;
    background-size: 300% 300%;
}

@keyframes text-gradient-anim {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.text-blue {
    color: rgb(21, 62, 204);
    font-weight: bold;
}

.alert {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    background-color: #1e1e1e;
    padding: 3rem;
    border-radius: 1rem;
    z-index: 100;
    animation: alert 4s ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes alert {
    0% {
        opacity: 0;
        transform: translateY(-150%);
    }

    20% {
        opacity: 1;
        transform: translateY(-50%);
    }

    80% {
        opacity: 1;
        transform: translateY(-50%);
    }

    100% {
        opacity: 0;
        transform: translateY(-150%);
    }
}

@media only screen
and (min-width: 350px)
and (max-width: 600px)
and (orientation: portrait) {
    .gradient-border {
        --borderWidth: 8px;
    }
}