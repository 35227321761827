.cybersecurity-project-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #333;
    margin: 2rem;
    border-radius: 1rem;
    height: 50vh;
}

.cybersecurity-project-header {
    font-size: 6rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
}

#cybersecurity-about-me-container {
    display: grid;
    grid-template-rows: 8rem auto auto;
    align-items: center;
    justify-content: center;
    background-color: #333;
    margin: 2rem;
    border-radius: 1rem;
    height: fit-content;
}

#cybersecurity-about-me-title {
    font-size: 4rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
    text-align: center;
}

#cybersecurity-about-me-text {
    font-size: 2.5rem;
    text-align: center;
    align-self: flex-start;
    padding: 0 2rem;
}

.cs-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}

.cybersecurity-view-portfolio-button {
    width: 16rem;
    aspect-ratio: 1;
    background-color: rgba(42, 42, 42, 0.8);
    backdrop-filter: blur(0.5rem);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition-property: transform, opacity;
    transition: 400ms;
    align-self: center;
    justify-self: center;
    margin: 3rem;
}

.cybersecurity-view-portfolio-button:hover {
    cursor: pointer;
    scale: 1.1;
}

#cybersecurity-portfolio-scroller-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cybersecurity-portfolio-scroller {
    display: grid;
    grid-template-columns: auto 12rem;
    grid-template-rows: 1fr;
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    background-color: #333;
    border-radius: 2rem;
}

.cybersecurity-portfolio-page {
    grid-column: 1/2;
    grid-row: 1/2;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto 1fr;
    display: none;
}

.active-cybersecurity-page {
    display: grid;
}

.cybersecurity-portfolio-page-title {
    font-size: 4rem;
    padding: 0;
    font-weight: bold;
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: flex-end;
    justify-self: left;
    margin: 0 0 0 4rem;
    text-align: left;
}

.cybersecurity-portfolio-page-subtitle  {
    font-size: 2.5rem;
    padding: 0;
    font-weight: 500;
    text-align: center;
    grid-column: 1/2;
    grid-row: 2/3;
    justify-self: left;
    margin: 0 0 0.5rem 4rem;
}

.cybersecurity-portfolio-page-text {
    grid-column: 1/2;
    grid-row: 3/4;
    justify-self: left;
    font-size: 1.5rem;
    margin: 0 0 0 4rem;
}

.cybersecurity-portfolio-page-link {
    width: 16rem;
    aspect-ratio: 1;
    background-color: rgba(42, 42, 42, 0.8);
    backdrop-filter: blur(0.5rem);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition-property: transform, opacity;
    transition: 400ms;
    align-self: center;
    justify-self: center;
    grid-column: 2/3;
    grid-row: 1/4;
    text-decoration: none;
    color: white;
}

.cybersecurity-portfolio-page-link:hover {
    cursor: pointer;
    scale: 1.1;
}

.cybersecurity-portfolio-page-link-label {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0;
    text-align: center;
    color: #fff;
    grid-column: 1/2;
    grid-row: 1/2;
    margin: 0;
    align-self: center;
    justify-self: center;
}
.cybersecurity-portfolio-page-link-icon {
    width: calc(100% - 8rem);
    aspect-ratio: 1;
    grid-column: 1/2;
    grid-row: 1/3;
    align-self: center;
    justify-self: center;
}

#cybersecurity-portfolio-scroller-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #333;
    border-radius: 2rem;
    margin: 2rem;
    align-self: center;
    justify-self: center;
}

.cybersecurity-project-indicator {
    height: 6rem;
}

.cybersecurity-infographic-container {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: fit-content;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cybersecurity-infographic-card {
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    background-color: #333;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cybersecurity-infographic-title {
    font-size: 4rem;
    font-weight: 500;
}

.cybersecurity-infographic-text {
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    padding: 0 4rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}