
.settings-container {
    background-color: #3b3b3b;
    height: auto;
    width: calc(100vw - 2em);
    margin: 1rem 1rem;
    display: grid;
    grid-template-areas:
        "aa ba ca da ea fa"
        "ab bb cb db eb fb"
        "ad bd cd dd ed fd"
        "ae be ce de ee fe";
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(5, 1fr);
    border-radius: 1rem;
}

#settings-title {
    grid-area: aa;
    margin: 1rem 0 0 1rem;
    padding: 0;
    align-self: center;
}

#randomize-button {
    grid-area: cb;
    background-color: #7b7c5c;
}

#randomize-button:hover {
    background-color: #92936e;
}

#bubble-sort-button {
    grid-area: ad;
}

#selection-sort-button {
    grid-area: bd;
}

#insertion-sort-button {
    grid-area: cd;
}

#merge-sort-button {
    grid-area: dd;
}

#quick-sort-button {
    grid-area: ed;
}

.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
    position: relative;
}

.slider {
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #4e4e4e;
    cursor: pointer;
}

.slider:hover::-webkit-slider-thumb {
    background-color: #8c8c63;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #7f8058;
    transition: background-color 200ms ease-in-out;
}
.slider::-webkit-slider-thumb:hover {
    background-color: #9e9f74;
}

.slider-label {
  position: absolute;
  top: calc(50% - 1.5rem);
  transform: translate(0, -50%);
}

#delay-slider-container {
    grid-area: ab;
}

#count-slider-container {
    grid-area: bb;
}

#delay-slider {
    width: 100%;
}

#count-slider {
    width: 100%;
}

#sort-time {
    grid-area: ae;
}

#swap-count {
    grid-area: ce;
}

#comparison-count {
    grid-area: be;
}

.statistic {
    align-self: center;
    justify-self: center;
}


.button {
    background-color: #55705e;
    color: white;
    border: none;
    border-radius: .3rem;
    margin: 1rem;
    padding: 1rem 0;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 200ms ease-in-out;
}

.button:hover {
    background-color: #61806b;
}


#array-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 50vh;
    margin: 0 1rem 1rem;
    background-color: #3b3b3b;
    padding: 1.5rem 1rem 1rem;
    border-radius: 1rem;
    overflow: hidden;
}

.array-element {
    background-color: #66339a;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    animation: fade-slide-in-linear 1s;
    opacity: 0;
    animation-fill-mode: forwards;
    box-sizing: border-box;
}

.array-value-text {
    position: absolute;
    transform: translate(-50%, -100%);
}

@keyframes fade-slide-in-linear {
    0% {
        opacity: 0;
        transform: translateY(-5vmin);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}