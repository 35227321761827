:root {
    --home-scroll-time: 48s;
    --second-scroll-time: calc(var(--home-scroll-time) * 0.75);
}

#home-page-container {
    position: relative;
    display: grid;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

#name-title-container {
    position: relative;
    justify-self: center;
    width: 75vw;
    /*max-width: 1080px;*/
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
}

#name-title {
    font-size: 5rem;
    color: transparent;
    background: linear-gradient(90deg,
    rgb(20, 100, 204),
    rgb(81, 67, 175),
    rgb(20, 100, 204),
    rgb(81, 67, 175)) 0 0 / 800% 100%;
    margin-left: 1rem;
    margin-bottom: 1rem;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-animation: text-gradient-anim 14s ease infinite;
    animation: text-gradient-anim 14s ease infinite;
    /*margin-top: 0.5rem;*/
}

@keyframes text-gradient-anim {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@-webkit-keyframes text-gradient-anim {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.title-text {
    font-family: Inter, sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #505056;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    display: inline;
    width: fit-content;
    text-decoration: none;
    transition: all 200ms ease, transform 400ms;
}

.title-link {
    position: relative;
    transition: all 200ms, padding-left 400ms cubic-bezier(.16,.16,.23,1.01);
}

.title-link:hover {
    color: transparent;
    background: linear-gradient(90deg,
    rgb(64, 36, 164),
    #373c9a,
    rgb(64, 36, 164),
    #373c9a) 0 0 / 800% 100%;
    background-clip: text;
    animation: text-gradient-anim 8s ease infinite;
    /*transform: translateX(1.5rem);*/
    padding-left: 1.5rem;
    transition: all 200ms ease, padding-left 400ms cubic-bezier(.16,.16,.23,1.01);
}

.title-bullet {
    background: linear-gradient(90deg,
    rgb(64, 36, 164),
    #373c9a,
    rgb(64, 36, 164),
    #373c9a) 0 0 / 800% 100%;
    animation: text-gradient-anim 8s ease infinite;
    width: 1rem;
    height: 2rem;
    display: inline-block;
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0rem, -50%);
    opacity: 0;
    border-radius: 10%;
    transition: all 200ms, transform 400ms cubic-bezier(.16,.16,.23,1.01), opacity 150ms linear;

}

.title-link:hover .title-bullet {
    opacity: 1;
    transform: translate(-1.5rem, -50%);
    transition: all 200ms, transform 400ms cubic-bezier(.16,.16,.23,1.01), opacity 500ms;
}

#home-card-container {
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 50vw;
    height: 200vh;
    transform: translate(60vw, -20vh) rotate(35deg);
    opacity: 0.5;
}

.home-card-column {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    flex-direction: column;
}

.home-card-column:nth-child(2) .home-card {
    animation: secondColumnScroll var(--second-scroll-time) infinite linear;
}

.home-card-column:nth-child(2) .home-card:nth-child(2) {
    animation-delay: calc(var(--second-scroll-time) * -1 / 3);
}

.home-card-column:nth-child(2) .home-card:nth-child(3) {
    animation-delay: calc(var(--second-scroll-time) * -2 / 3);
}

.home-card {
    position: absolute;
    width: calc(25vw - 1rem);
    height: 75vh;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    background-color: rgb(18, 18, 18);
    border-radius: 1rem;
    animation: firstColumnScroll var(--home-scroll-time) infinite linear;
    transition: background-color 400ms ease;
    overflow: hidden;
}

.home-card div {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 400ms ease;
}

.home-card:nth-child(2) {
    animation-delay: calc(var(--home-scroll-time) * -0.25);
}

.home-card:nth-child(3) {
    animation-delay: calc(var(--home-scroll-time) * -0.5);
}

.home-card:nth-child(4) {
    animation-delay: calc(var(--home-scroll-time) * -0.75);
}

@keyframes firstColumnScroll {
    0% {
        transform: translateY(calc(-100% - 4rem));
    }

    100% {
        transform: translateY(300%);
    }
}

@keyframes secondColumnScroll {
    0% {
        transform: translateY(calc(-50% - 1.5rem));
    }

    100% {
        transform: translateY(calc(250% + 1.5rem));
    }
}

.home-card-column .home-card:nth-child(odd) div {
    opacity: 0;
    background: linear-gradient(35deg,
    rgb(64, 36, 164),
    #373c9a,
    rgb(64, 36, 164),
    #373c9a) 0 0 / 800% 100%;
    animation: text-gradient-anim 8s ease infinite;
}

.home-card-column .home-card:nth-child(even) div {
    opacity: 0;
    background: linear-gradient(35deg,
    rgb(36, 115, 164),
    #374b9a,
    rgb(36, 115, 164),
    #374b9a) 0 0 / 800% 100%;
    animation: text-gradient-anim 8s ease infinite;
}
#spacer {
    display: none;
}
@media only screen
and (min-width: 350px)
and (max-width: 600px)
and (orientation: portrait) {
    #home-page-container{
        height: calc(var(--vh, 1vh) * 100);
    }

    #home-card-container {
        display: none;
    }

    #name-title-container {
        text-align: center;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    #name-title {
        /*margin-top: 50%;*/
        white-space: nowrap;
    }

    .title-text {
        font-weight: 500;
    }

    .title-link {
        color: #858585;
        font-size: 3.5rem;
        z-index: 1;
        padding: 0.5rem 1rem;
    }

    .title-link::after {
        background-color: #1e1e1e;
        width: 100%;
        height: 100%;
        display: block;
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 1rem;
    }

    /*#spacer {*/
    /*    display: block;*/
    /*    width: 100%;*/
    /*    height: 10rem;*/
    /*}*/
}

